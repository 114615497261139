<template>
    <div class="product-components ">
        <router-view />

    </div>
</template>

<script>

    export default {
        name: "product",
    }
</script>

<style scoped lang="less">
</style>